<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-yellow">
                <div class="card-icon">
                    <md-icon>3d_rotation</md-icon>
                </div>
                <h4 class="title">Library - VIRTUAL</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div v-for="theme in themeList" :key="theme._id" class="md-layout-item md-size-25">
                            <img class="theme-thumbnail" :src="theme.thumbnailUrl" alt="..."><br />
                            <md-radio @change="changeTheme" v-model="selectedTheme" :value="theme._id" :disabled="theme.status==='public' ? false : true">{{theme.name}}</md-radio><br /> 
                            <md-button class="md-dense md-raised md-success" @click="clickPreview(theme.thumbnailUrl)" :disabled="theme.status==='public' ? false : true">Preview</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>local_activity</md-icon>
                </div>
                <h4 class="title">Invitation Code</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-switch class="cast-controller" v-model="isInvitation">Code on/off</md-switch>
                        </div>
                        <div class="md-layout-item md-size-90">
                            <md-field>
                                <label>ex) secret-code</label>
                                <md-input v-model="showroom.invitationCode"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-10">
                            <md-button class="md-dense md-raised md-success" @click="saveInvitationCode">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-red">
                <div class="card-icon">
                    <md-icon>library_music</md-icon>
                </div>
                <h4 class="title">BGM</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-switch v-model="showroom.bgmStatus" class="cast-controller">BGM on/off</md-switch>
                        </div>
                        <div class="md-layout-item md-size-90">
                            <md-field>
                                <label>파일 선택(50MB 이하) - mp3</label>
                                <md-file v-model="showroom.bgmUrl" @change="onChangeFile($event)" accept="*" />
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-10">
                            <md-button class="md-dense md-raised md-success" @click="saveBGM">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <transition>
        <div v-if="isModalOpen" class="modal-box">
            <div class="modal-header-box md-layout">
                <div class="md-layout-item md-size-50"><h5>Preview</h5></div>
                <div class="md-layout-item md-size-50"><h5 class="modal-close" @click="toggleModal">X</h5></div>
            </div>
            <div class="thumbnail-box">
                <img :src="selectedPreview" alt="...">
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
import Crypto from "crypto-js";

export default {
    components: {

    },
    data() {
        return {
            showroom: {},
            bgm: null,
            isInvitation: false,
            themeList: [],
            selectedTheme: null,
            isModalOpen: false,
            selectedPreview: null
        }
    },
    async created() {
        const res = await axios.get(api.GET_DATA(this.$route.params.exhibitionId));
        if(res.data.success) {
            this.showroom = res.data.data;
            
            if(this.showroom.status === "invitation") this.isInvitation = true;
            if(this.showroom.invitationCode) {
                this.showroom.invitationCode = Crypto.AES.decrypt(this.showroom.invitationCode, process.env.VUE_APP_INVITATION_CODE_SECRET).toString(Crypto.enc.Utf8);
            }

            this.selectedTheme = this.showroom.theme._id;
        }

        const themeRes = await axios.get(api.GET_THEME_LIST("virtual"),
        { 
            headers: { 
                authorization: this.$store.getters.getToken,
            }
        });
        if(themeRes.data.success) {
            this.themeList = themeRes.data.data;
        }
    },
    methods: {
        async onChangeFile(e) {
            this.bgm = e.target.files[0];
        },
        async saveInvitationCode() {
            const data = new FormData();

            data.append("status", this.isInvitation ? "invitation" : "public");
            data.append("invitationCode", this.showroom.invitationCode);

            const res = await axios.put(api.UPDATE_INVI_CODE(this.$route.params.exhibitionId),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "저장 성공");
        },
        async saveBGM() {
            const data = new FormData();

            data.append("bgmStatus", this.showroom.bgmStatus);
            this.bgm ? data.append("data", this.bgm) : null;

            const res = await axios.put(api.UPDATE_BGM(this.$route.params.exhibitionId),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "저장 성공");
        },
        async changeTheme() {
            const res = await axios.put(api.UPDATE_THEME(this.$route.params.exhibitionId, this.selectedTheme), {},
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "테마 변경 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "테마가 변경되었습니다.");
        },
        clickPreview(url) {
            this.isModalOpen = true;
            this.selectedPreview = url;
        },
        toggleModal() {
            this.isModalOpen = !this.isModalOpen;
        }
    }
}
</script>

<style scoped>
label {
    /* font-weight: bold; */
}
.theme-thumbnail {
    height: 200px;
}
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    width: 500px;
    height: 400px;
    z-index: 1;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 6px;
}
.modal-header-box > .md-layout-item > h5 {
    margin-top: 0px;
    margin-bottom: 1rem;
    font-weight: bolder;
}
.modal-header-box > .md-layout-item {
    padding: 0px;
}
.thumbnail-box {
    width: 100%;
}
.thumbnail-box > img {
    width: 100%;
    max-height: 250px;
}
.modal-close {
    cursor: pointer;
    text-align: end;
}
.md-button.md-success, .md-button.md-success:hover, .md-button.md-success:focus, .md-button.md-success:active, .md-button.md-success.active, .md-button.md-success:active:focus, .md-button.md-success:active:hover, .md-button.md-success.active:focus, .md-button.md-success.active:hover, .open > .md-button.md-success.dropdown-toggle, .open > .md-button.md-success.dropdown-toggle:focus, .open > .md-button.md-success.dropdown-toggle:hover {
    background-color: #38ac90 !important;
}
.md-switch.md-theme-default.md-checked .md-switch-thumb {
    background-color: white !important;
    background-color: var(--md-theme-default-accent, #38ac90) !important;
}

</style>
<style>
.md-switch.md-checked .md-switch-container {
    background-color: #38ac90 !important;
}
.md-switch.md-checked .md-switch-thumb {
    border: 1px solid #a2d1c6 !important;
}
.md-radio.md-checked .md-radio-container {
    border-color: #38ac90 !important;
}
.md-radio.md-checked .md-radio-container:after {
    background-color: #38ac90 !important;
}
.md-card .md-card-header-red .card-icon {
    background: linear-gradient(60deg, #ef5350, #e53935);
}
.md-card .md-card-header-yellow .card-icon {
    background: linear-gradient(60deg, #fb8c00, #ffa726);
}
</style>